exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-cms-js": () => import("./../../../src/pages/cms.js" /* webpackChunkName: "component---src-pages-cms-js" */),
  "component---src-pages-confirmation-js": () => import("./../../../src/pages/confirmation.js" /* webpackChunkName: "component---src-pages-confirmation-js" */),
  "component---src-pages-crypto-chart-js": () => import("./../../../src/pages/crypto-chart.js" /* webpackChunkName: "component---src-pages-crypto-chart-js" */),
  "component---src-pages-crypto-mentions-js": () => import("./../../../src/pages/crypto-mentions.js" /* webpackChunkName: "component---src-pages-crypto-mentions-js" */),
  "component---src-pages-crypto-prices-js": () => import("./../../../src/pages/crypto-prices.js" /* webpackChunkName: "component---src-pages-crypto-prices-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-market-insights-js": () => import("./../../../src/pages/market-insights.js" /* webpackChunkName: "component---src-pages-market-insights-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-sentiment-logs-js": () => import("./../../../src/pages/sentiment-logs.js" /* webpackChunkName: "component---src-pages-sentiment-logs-js" */),
  "component---src-pages-sign-in-js": () => import("./../../../src/pages/sign-in.js" /* webpackChunkName: "component---src-pages-sign-in-js" */),
  "component---src-pages-subscribe-checkout-js": () => import("./../../../src/pages/subscribe/checkout.js" /* webpackChunkName: "component---src-pages-subscribe-checkout-js" */),
  "component---src-pages-subscription-plans-js": () => import("./../../../src/pages/subscription-plans.js" /* webpackChunkName: "component---src-pages-subscription-plans-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-watchlist-category-js": () => import("./../../../src/pages/watchlist-category.js" /* webpackChunkName: "component---src-pages-watchlist-category-js" */),
  "component---src-pages-watchlist-js": () => import("./../../../src/pages/watchlist.js" /* webpackChunkName: "component---src-pages-watchlist-js" */)
}

